class PersistenceStorage {
	constructor(storage_) {
		this.storage = storage_;
	}

	/**
	 * set an item in the storage
	 * @param {String} key_ - key of the value to set
	 * @param {String} value_ - value to set
	 * @returns {void}
	 */
	setItem(key_, value_) {
		this.storage.setItem(key_, value_);
	}

	/**
	 * get an item from the storage
	 * @param {String} key_ - the key to get the value for
	 * @returns {String} - the matching value to the key
	 */
	getItem(key_) {
		return this.storage.getItem(key_);
	}

	/**
	 * remove and item from the storage
	 * @param {*} key_ - the key to be removed
	 * @returns {void}
	 */
	removeItem(key_) {
		this.storage.removeItem(key_);
	}
}

export default PersistenceStorage;
