import PersistenceStorage from './PersistenceStorage';
import StorageTypes from './StorageTypes';

const STORES_REGISTRY_KEY = 'audi-microkernel-stores';

class Persistence {
	constructor(stateRegistry_, storageType_, lifetime_) {
		if (!Persistence._instances) {
			Persistence._instances = [];
		}

		if (Persistence._instances[storageType_]) {
			return Persistence._instances[storageType_];
		}

		this._stateRegistry = stateRegistry_;
		this._lifetime = lifetime_;
		this.storageType = storageType_;

		this._initialize();

		Persistence._instances[storageType_] = this;
	}

	_initialize() {
		if (this.storageType === StorageTypes.LOCAL_STORAGE) {
			this.storage = new PersistenceStorage(
				window[StorageTypes.LOCAL_STORAGE],
			);
		} else {
			this.storage = new PersistenceStorage(
				window[StorageTypes.SESSION_STORAGE],
			);
		}

		this._onLoad = this._onLoad.bind(this);
		this._processLoadedStore = this._processLoadedStore.bind(this);
		this._subscribePersistenceToLocalStorageStores =
			this._subscribePersistenceToLocalStorageStores.bind(this);
		this._loadStoreFromStorage = this._loadStoreFromStorage.bind(this);
		this._persistStoreToStorage = this._persistStoreToStorage.bind(this);
		this._persistStoresToStorage = this._persistStoresToStorage.bind(this);

		window.addEventListener('load', this._onLoad, { once: true });

		if (this.storageType !== StorageTypes.LOCAL_STORAGE) {
			window.addEventListener(
				'beforeunload',
				this._persistStoresToStorage,
			);
		}
	}

	/**
	 * event handler for the load event
	 * @returns {void}
	 */
	_onLoad() {
		const existingStoresInStorage = this._getArrayFromCommaSeparatedString(
			this.storage.getItem(STORES_REGISTRY_KEY),
		);

		existingStoresInStorage.forEach((storeName) => {
			const loadedStore = this._loadStoreFromStorage(storeName);
			this._processLoadedStore(loadedStore, storeName);
		});
		this._subscribePersistenceToLocalStorageStores();
	}

	/**
	 * load a store from the storage
	 * @param {String} nameOfStore_ - name of store to load
	 * @returns {Object} - object with state and actions of this store
	 */
	_loadStoreFromStorage(nameOfStore_) {
		const persistedStore = this.storage.getItem(nameOfStore_);
		const combinedStoreDataAndActions = JSON.parse(persistedStore);

		if (combinedStoreDataAndActions && combinedStoreDataAndActions.state) {
			const state = combinedStoreDataAndActions.state;
			const actions = this._transformStringToActions(
				combinedStoreDataAndActions.actions,
			);
			const timestamp = combinedStoreDataAndActions.timestamp;

			return { actions, state, timestamp };
		}
	}

	/**
	 * process a loaded store
	 * @param {Object} loadedStore - store loaded from storage
	 * @param {string} storeName - name of the store
	 * @returns {void}
	 */
	_processLoadedStore(loadedStore, storeName) {
		if (loadedStore) {
			const writeDate = loadedStore.timestamp
				? loadedStore.timestamp
				: -1;

			if (writeDate === -1 || this._isWithinLifecycle(writeDate)) {
				const hasReallyBeenAdded = this._stateRegistry.addStore(
					storeName,
					loadedStore.state,
					loadedStore.actions,
					{
						storageType: this.storageType,
						timestamp: loadedStore.timestamp,
					},
				);

				if (hasReallyBeenAdded) {
					console.info(
						'µK persistence: ' +
							storeName +
							' has been restored from ' +
							this.storageType,
					);
				}
			} else {
				this._removeStoreFromStorage(storeName);
				console.info(
					'µK persistence: ' +
						storeName +
						' has expired and therefor been removed',
				);
			}
		}
	}

	/**
	 * subscribe to localStorage stores
	 * @returns {void}
	 */
	_subscribePersistenceToLocalStorageStores() {
		Object.keys(this._stateRegistry.storesToPersist).forEach((storeKey) => {
			if (
				this._stateRegistry.storesToPersist[storeKey].storageType ===
					StorageTypes.LOCAL_STORAGE &&
				this.storageType === StorageTypes.LOCAL_STORAGE
			) {
				this._stateRegistry.subscribeToStore(
					storeKey,
					this._persistStoreToStorage,
				);
			}
		});
	}

	/**
	 * check whether store is still valid
	 * @param {int} timestamp_ - the timestamp to check
	 * @returns {boolean} - whether the given timestamp is not too old
	 */
	_isWithinLifecycle(timestamp_) {
		const currentTimestamp = Date.now();
		const elapsedTime = currentTimestamp - timestamp_;
		return (
			this._lifetime === StorageTypes.LIFETIME_UNLIMITED ||
			elapsedTime < this._lifetime
		);
	}

	/**
	 * get an array from a comma separated string
	 * @param {String} commaSeparatedString_ - a comma separated string (may be null or undefined)
	 * @returns {Array} - array of single values, or empty array
	 */
	_getArrayFromCommaSeparatedString(commaSeparatedString_) {
		return commaSeparatedString_ ? commaSeparatedString_.split(',') : [];
	}

	/**
	 * transform a string to an action object
	 * @param {String} persistedActions - string representation of actions
	 * @returns {Object} - object containing the action names as keys, and the corresponding functions as values
	 */
	_transformStringToActions(persistedActions) {
		const expandedActionFunctions = {};
		const actionsObject = JSON.parse(persistedActions);

		Object.keys(actionsObject).forEach((key) => {
			/* eslint-disable-next-line no-new-func */
			expandedActionFunctions[key] = Function(
				'return ' + actionsObject[key],
			)();
		});

		return expandedActionFunctions;
	}

	/**
	 * persist all persistable stores to the storage
	 * @returns {void}
	 */
	_persistStoresToStorage() {
		const storesToPersist = Object.keys(
			this._stateRegistry.storesToPersist,
		);

		storesToPersist.forEach((storeToPersist) => {
			if (this._isStoreToBePersisted(storeToPersist)) {
				const persistableState =
					this._stateRegistry.getState(storeToPersist);
				this._persistStoreToStorage(persistableState, storeToPersist);
			}
		});
	}

	/**
	 * persist a given store to its storage
	 * @param {object} state_ - the current state of the store
	 * @param {string} storeToPersist_ - the name of the store
	 * @returns {void}
	 */
	_persistStoreToStorage(state_, storeToPersist_) {
		const existingStoresInStorage = this._getArrayFromCommaSeparatedString(
			this.storage.getItem(STORES_REGISTRY_KEY),
		);

		if (existingStoresInStorage.indexOf(storeToPersist_) === -1) {
			existingStoresInStorage.push(storeToPersist_);
			this.storage.setItem(
				STORES_REGISTRY_KEY,
				existingStoresInStorage.join(','),
			);
		}

		const persistableActions = this._transformActionsToString(
			this._stateRegistry.getPersistableActions(storeToPersist_),
		);
		const lastChangedDate = this._stateRegistry.getTimestampFromPersistence(
			storeToPersist_,
		)
			? this._stateRegistry.getTimestampFromPersistence(storeToPersist_)
			: Date.now();
		const stringifiedStore = JSON.stringify({
			actions: persistableActions,
			state: state_,
			timestamp: lastChangedDate,
		});
		this.storage.setItem(storeToPersist_, stringifiedStore);
	}

	/**
	 * remove a store from the storage
	 * @param {String} storeName_ - the store to remove
	 * @returns {void}
	 */
	_removeStoreFromStorage(storeName_) {
		const newKeysString =
			this._getUpdatedStoresRegistryWithoutStore(storeName_);

		if (newKeysString !== '') {
			this.storage.setItem(STORES_REGISTRY_KEY, newKeysString);
		} else {
			this.storage.removeItem(STORES_REGISTRY_KEY);
		}
		this.storage.removeItem(storeName_);
	}

	_getUpdatedStoresRegistryWithoutStore(storeName_) {
		const storageItem = this.storage.getItem(STORES_REGISTRY_KEY);
		const existingStoresInStorage =
			this._getArrayFromCommaSeparatedString(storageItem);
		const newStoreItemsInStorage = existingStoresInStorage.filter(
			(currentName) => currentName !== storeName_,
		);

		return newStoreItemsInStorage.join(',');
	}

	/**
	 * shall this store persisted in this persistence instance
	 * @param {String} storeName_ - name of the store
	 * @returns {boolean} whether it is applicable
	 */
	_isStoreToBePersisted(storeName_) {
		const storeInformation =
			this._stateRegistry.storesToPersist[storeName_];
		return storeInformation.storageType === this.storageType;
	}

	/**
	 * get a string representation of an actions object
	 * @param {Object} actionsObject - actions object
	 * @returns {String} - string representation of actions object
	 */
	_transformActionsToString(actionsObject) {
		const expandedActionsObject = {};

		Object.keys(actionsObject).forEach((key) => {
			expandedActionsObject[key] = actionsObject[key].toString();
		});
		return JSON.stringify(expandedActionsObject);
	}
}

export default Persistence;
