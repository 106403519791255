import Persistence from './Persistence';
import StorageTypes from './StorageTypes';
import { stateRegistry } from 'microkernel';

const persistenceSession = new Persistence(
	stateRegistry,
	StorageTypes.SESSION_STORAGE,
	StorageTypes.LIFETIME_UNLIMITED,
);
const persistenceLocalStorage = new Persistence(
	stateRegistry,
	StorageTypes.LOCAL_STORAGE,
	StorageTypes.LIFETIME_ONE_DAY,
);

export { persistenceSession, persistenceLocalStorage };
